<template>
  <div id="app">
    <div>
      <Header v-if="$route.name === 'Main'" :currentLanguage="this.currentLanguage" />
      <HeaderDark
          v-if="$route.name !== 'Main' &&
          $route.name !== 'NotFound' &&
          $route.name !== 'SeeYouSoon' &&
          $route.name !== 'karpaty_ua' &&
          $route.name !== 'architectural-company-archimatika' &&
          // $route.name !== 'bigboard' &&
          $route.name !== 'big_idea' &&
          // $route.name !== 'hackenproof' &&
          $route.name !== 'residential-area-manhattan' &&
          // $route.name !== 'prykarpatty-oblenergo' &&
          $route.name !== 'uis' &&
          $route.name !== 'construction-company-vertical' &&
          $route.name !== 'teple-misto'"
          :currentLanguage="this.currentLanguage"
      />
      <router-view />
      <foo-bar
          v-if="$route.name !=='NotFound' &&
          $route.name !== 'SeeYouSoon' &&
          $route.name !== 'karpaty_ua' &&
          $route.name !== 'architectural-company-archimatika' &&
          // $route.name !== 'bigboard' &&
          $route.name !== 'big_idea' &&
          // $route.name !== 'hackenproof' &&
          $route.name !== 'residential-area-manhattan' &&
          // $route.name !== 'prykarpatty-oblenergo' &&
          $route.name !== 'uis' &&
          $route.name !== 'construction-company-vertical' &&
          $route.name !== 'teple-misto'"
      />
    </div>
  </div>
</template>

<script>
import Header from '@/components/HeaderLight.vue';
import HeaderDark from "@/components/HeaderDark";
import FooBar from "@/components/FooBar";

export default {
  data() {
    return {
      currentLanguage: ""
    }
  },
  components: {
    FooBar,
    Header,
    HeaderDark
  },
  mounted() {
    const changeLanguage = () => {
      const EN_LANGUAGE = 'en';
      const UA_LANGUAGE = 'ua';

      const setLanguage = (language) => {
        this.currentLanguage = language;
        this.$root.$i18n.locale = language;
        this.$forceUpdate();
      };

      fetch('https://ipapi.co/json/')
          .then(res => res.json())
          .then(data => {
            const countryCode = data.country_code;
            const language = countryCode === 'UA' ? UA_LANGUAGE : EN_LANGUAGE;
            setLanguage(language);
          })
          .catch((error) => {
            console.error('Failed to detect country, defaulting to English.', error);
            setLanguage(EN_LANGUAGE);
          });
    }

    changeLanguage();
  }
}
</script>

<style lang="scss">
@import "base/styles/global";
@import "base/styles/cookies";
</style>